<template>
  <b-overlay
    id="overlay-background"
    :show="loading"
    blur="10px"
    opacity="0.30"
    rounded="sm"
    variant="light"
  >
    <section class="invoice-preview-wrapper font-weight-bolder">

      <!--    Alert: No item found-->
      <b-alert
        variant="danger"
        :show="allItems === undefined"
      >
        <h4 class="alert-heading">
          No invoice found with this invoice id
        </h4>
      </b-alert>

      <b-row
        :v-if="allItems"
        class="invoice-preview"
      >

        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            id="invoice-preview-card"
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0 invoice-dark">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div class="logo-wrapper">

                  <!--                <logo />-->
                  <img
                    style=" height: 200px; width: 200px"
                    :src="allItems.business_profile.company_logo ? `${bucketUrl}${allItems.business_profile.company_logo}` : defaultCompanyLogo"
                    alt="Company Logo"
                  >
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2 business-details">
                  <div class="invoice-title">
                    {{ allItems.business_profile.company_name }}
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="card-text mb-25">
                      {{ allItems.business_profile.company_address }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="card-text mb-0">
                      {{ allItems.business_profile.company_phone }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="card-text mb-0">
                      {{ allItems.business_profile.company_finance_email }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              v-if="invoiceData.client"
              class="invoice-padding pt-0 invoice-dark"
            >
              <b-row class="invoice-spacing merchant-detatils">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0"
                >
                  <div
                    v-if="!walkingCustomer"
                    class="mb-1 invoice-margin invoice-dark"
                  >
                    Merchant Name:  {{ allItems.merchant.name }}
                  </div>
                  <div
                    v-if="walkingCustomer"
                    class="mb-1 invoice-margin invoice-dark"
                  >
                    Walking Customer Name:  {{ allItems.walking_customer.name }}
                  </div>
                  <div
                    v-if="!walkingCustomer"
                    class="mb-1 invoice-margin invoice-dark"
                  >
                    Merchant Type:  {{ allItems.merchant.merchant_setting.merchant_type === 'credit' ? 'Credit' : 'Cash' }}
                  </div>
                  <div
                    v-if="!walkingCustomer"
                    class="mb-1 invoice-margin invoice-dark"
                  >
                    Merchant Business:  {{ allItems.merchant_business }}
                  </div>
                  <div
                    v-if="!walkingCustomer"
                    class="mb-1 invoice-margin invoice-dark"
                  >
                    Merchant Address:  {{ allItems.address.address_line_1 + allItems.address.address_line_2 + allItems.address.address_line_3 }}
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end invoice-number"
                >
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            <span class="font-weight-bolder">
                              Invoice No:
                            </span>
                          </td>
                          <td><span class="font-weight-bolder">{{ allItems.invoice_no }}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <span class="font-weight-bolder">
                              Invoice Date:
                            </span>
                          </td>
                          <td><span class="font-weight-bolder">{{ __dateTimeFormatter(allItems.updated_at) }}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-table-lite
              v-if="!walkingCustomer"
              class="delivery-management-service-table invoice-des-tb"
              responsive
              :items="rows"
              :fields="invoiceTableStructure"
            >
              <template #cell(card_name)>
                Delivery Management Service
              </template>
              <template #cell(total_collected_cod)="data">
                {{ __numberWithCommas(data.value, true) }}
              </template>
              <template #cell(delivery_charge)="data">
                {{ __numberWithCommas(data.value, true) }}
              </template>
              <template #cell(commission)="data">
                <div v-if="data.value !== 0">
                  {{ __numberWithCommas(data.value, true) }}
                </div>
                <div v-else>
                  --
                </div>
              </template>
              <template #cell(total_charges)="data">
                <div v-if="data.value !== 0">
                  {{ __numberWithCommas(data.value, true) }}
                </div>
                <div v-else>
                  --
                </div>
              </template>
            </b-table-lite>
            <b-table-lite
              v-else
              class="delivery-management-service-table invoice-des-tb2"
              responsive
              :items="[...rows,...walkingInvoiceData]"
              :fields="walkingInvoiceTableStructure"
            >
              <template #cell(card_name)="data">
                {{ data.value ? 'Delivery Management Service Fee': '' }}
              </template>
              <template #cell(delivery_charge)="data">
                {{ data.value === 'NA' ? '' : __numberWithCommas(data.value, true) }}
              </template>
            </b-table-lite>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0 invoice-dark">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  class="mt-md-6 d-flex"
                  order="1"
                  order-md="2"
                >
                  <div>
                    <table class="account-statment-table">
                      <tbody>
                        <tr v-if="!walkingCustomer">
                          <td class="pr-1">
                            <div class="mb-1 account-statement invoice-dark">
                              {{ allItems.business_profile.company_name }} Account Statement
                            </div>
                          </td>
                        </tr>
                        <tr v-if="!walkingCustomer">
                          <td class="pr-1 width-250">
                            Total COD Value Collected:
                          </td>
                          <td class="w-50 pl-2 text-right finance-section">
                            <span>{{ businessSettings.company_currency }} {{ __numberWithCommas(allItems.rate_card.total_amounts.total_collected_cod, true) }}</span>
                          </td>
                        </tr>
                        <br>
                        <tr v-if="!walkingCustomer">
                          <td class="pr-1 font-weight-bolder">
                            Total Charges
                          </td>
                        </tr>
                        <tr v-if="!walkingCustomer">
                          <td class="pr-1">
                            Delivery charge
                          </td>
                          <td class="w-50 pl-2 text-right finance-section">
                            <span>{{ businessSettings.company_currency }} {{ __numberWithCommas(allItems.rate_card.total_amounts.delivery_charge, true) }}</span>
                          </td>
                        </tr>
                        <tr v-if="!walkingCustomer">
                          <td class="pr-1">
                            Commission
                          </td>
                          <td class="w-50 pl-2 text-right finance-section">
                            <span>{{ businessSettings.company_currency }} {{ __numberWithCommas(allItems.rate_card.total_amounts.commission, true) }}</span>
                          </td>
                        </tr>
                        <br>
                        <tr v-if="!walkingCustomer && allItems.tax_breakdown != null">
                          <td class="pr-1 width-250">
                            Invoice Amount Before Tax:
                          </td>
                          <td class="w-50 pl-2 text-right finance-section">
                            <span>{{ businessSettings.company_currency }} {{ __numberWithCommas(allItems.invoice_amount, true) }}</span>
                          </td>
                          <br>
                        </tr>
                        <tr v-if="!walkingCustomer && allItems.tax_breakdown">
                          <td class="pr-1 font-weight-bolder">
                            Tax Breakdown
                          </td>
                        </tr>
                        <div
                          v-if="!walkingCustomer && allItems.tax_breakdown != null"
                          class="taxSection"
                        >
                          <tr
                            v-for="(taxItems, index) in taxes"
                            :key="index"
                          >
                            <td class="pr-1 width-250">
                              {{ taxItems.tax_name }}({{ taxItems.rate_applied }}{{ taxItems.tax_rate_type == 'Fixed'?'':'%' }})
                            </td>
                            <td class="w-50 pl-2 text-right finance-section">
                              <span>{{ businessSettings.company_currency }} {{ __numberWithCommas(taxItems.tax_amount, true) }}</span>
                            </td>
                          </tr>
                          <br>
                        </div>
                        <tr v-if="!walkingCustomer && allItems.tax_breakdown != null">
                          <td class="pr-1 width-250">
                            Total Tax Charge:
                          </td>
                          <td class="w-50 pl-2 text-right finance-section">
                            <span>{{ businessSettings.company_currency }} {{ __numberWithCommas(allItems.total_tax_applied, true) }}</span>
                          </td>
                          <br>
                        </tr>
                        <tr v-if="!walkingCustomer && allItems.tax_breakdown == null">
                          <td class="pr-1">
                            {{ '' }}
                          </td>
                          <td class="w-50 pl-2 text-right finance-section">
                            <span>--------</span>
                          </td>
                        </tr>
                        <tr v-if="!walkingCustomer && allItems.tax_breakdown == null">
                          <td class="pr-1">
                            {{ '' }}
                          </td>
                          <td class="w-50 pl-2 text-right finance-section">
                            <span>{{ businessSettings.company_currency }} {{ __numberWithCommas(allItems.rate_card.total_amounts.total_charges, true) }}</span>
                          </td>
                        </tr><br>
                        <tr v-if="!walkingCustomer && allItems.tax_breakdown != null">
                          <td class="pr-1 width-250">
                            Invoice Amount After Tax:
                          </td>
                          <td class="w-50 pl-2 text-right finance-section">
                            <span>{{ businessSettings.company_currency }} {{ __numberWithCommas(allItems.invoice_amount_after_taxes, true) }}</span>
                          </td>
                        </tr>
                        <br>
                        <tr v-if="!walkingCustomer">
                          <td class="pr-1">
                            <div class="">
                              NET Payable to {{ walkingCustomer ? allItems.walking_customer.name : allItems.merchant.name }}
                            </div>
                          </td>
                          <td class="w-50 pl-2 text-right finance-section">
                            <span>{{ businessSettings.company_currency }} {{ __numberWithCommas(walkingCustomer ? 0 : allItems.rate_card.total_amounts.net_payable, true) }}</span>
                          </td>
                        </tr>
                        <hr class="invoice-spacing">
                        <tr>
                          <td class="pr-1">
                            -----------------------------
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span class="font-weight-bolder">Head of Finance</span>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0 invoice-dark">
              <span class="font-weight-bolder">Remarks: </span>
              <br>
              <span>* If you need any clarifications on this. please do not hesitate to contact the head of finance.</span>
              <br>
              <span>* Please refer the annexure for more details attached herewith.</span>
            </b-card-body>
            <!-- table -->
            <h4
              class="invoice-title mb-2"
              style="margin-top:300px; margin-left:27px"
            >
              Invoice Orders
            </h4>
            <b-overlay
              id="overlay-background"
              :show="loading"
              blur="10px"
              opacity="0.30"
              rounded="sm"
              variant="light"
            >
              <b-table-lite
                class="invoice-order-table invoice-order-tb"
                :fields="fields"
                :items="orderRows"
                hover
                responsive
                striped
              >
                <template #cell(waybill_number)="data">
                  <div class="d-flex align-items-center width-100 print-layout">
                    <b-avatar
                      class="avatar-text"
                      :text="avatarText(data.value)"
                      size="32"
                      variant="light-primary"
                    />
                    <div>
                      <div
                        class="font-weight-bolder ml-1 text-primary waybill-number-cell"
                        @click="onClickViewOrder(data.item.id)"
                      >
                        {{ data.value }}
                      </div>
                      <div class="font-small-2 ml-1 waybill-number-text">
                        {{ __dateTimeFormatter(data.item.created_at) }}
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(customer_name)="data">
                  <div class="width-60">
                    {{ data.value }}
                  </div>
                </template>
                <template #cell(customer_email)="data">
                  <div class="d-flex align-items-center width-190">
                    <div>
                      <div class="font-weight-bolder w-75 font-small-4">
                        {{ data.value }}
                      </div>
                      <div class="font-small-2 w-75">
                        <div><b>Primary number: </b>{{ data.item.customer_phone }}</div>
                      </div>
                      <div
                        v-if="data.item.customer_secondary_phone !== null"
                        class="font-small-2 w-75"
                      >
                        <div><b>Second number: </b>{{ data.item.customer_secondary_phone }}</div>
                      </div>
                      <div class="font-small-2 w-50">
                        {{ data.item.customer_address }}
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(finance)="data">
                  <div class="width-190">
                    <div class="width-190 d-flex justify-content-between cod-section">
                      <b>Total&nbsp;:</b>
                      <span class="text-right">{{ __numberWithCommas(data.item.cod, true) }}</span>
                    </div>
                    <div style="height:8px;" />
                    <div class="width-190 d-flex justify-content-between cod-section">
                      <b>Collected&nbsp;:</b>
                      <span class="text-right">{{ __numberWithCommas(data.item.collected_cod, true) }}</span>
                    </div>
                    <div style="height:8px;" />
                    <div class="width-190 d-flex justify-content-between cod-section">
                      <b>Delivery Charge&nbsp;:</b>
                      <span class="text-right">{{ __numberWithCommas(data.item.delivery_charge, true) }}</span>
                    </div>
                  </div>
                </template>
                <template #cell(weight)="data">
                  <div class="width-50">
                    {{ data.value }}
                  </div>
                </template>
                <template #cell(description)="data">
                  <div class="width-190 w-50">
                    {{ data.value ? data.value : '--' }}
                  </div>
                </template>
                <template #cell(status.key)="data">
                  <order-status-badge :status-key="data.value" />
                </template>
              </b-table-lite>
            </b-overlay>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card>

            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-success"
              class="mb-75"
              block
              @click="printInvoice"
            >
              Print
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <view-order-modal ref="viewOrderModal" />
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BButton,
  BAlert,
  VBToggle,
  BOverlay,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import OrderStatusBadge from '@core/components/status/OrderStatusBadge.vue'
import ViewOrderModal from '@/components/AllOrders/ViewOrderModal.vue'
import { bucketUrl, defaultCompanyLogo } from '@/constants/config'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import Printd from 'printd'

const FinanceRepository = RepositoryFactory.get('finance')
export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BButton,
    BAlert,
    BOverlay,
    BAvatar,
    OrderStatusBadge,
    ViewOrderModal,
  },
  data() {
    let walkingCustomer = false
    if (window.location.href.includes('walking')) walkingCustomer = true
    // eslint-disable-next-line no-unused-vars
    const invoiceTableStructure = [
      {
        key: 'card_name',
        label: 'Name',
      },
      {
        key: 'order_count',
        label: 'Order Count',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        key: 'total_collected_cod',
        label: 'Total Collected COD',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        key: 'delivery_charge',
        label: 'Delivery Charge',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        key: 'commission',
        label: 'Commission',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        key: 'total_charges',
        label: 'Total Charges',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
    ]
    const invoiceDescription = [
      {
        Waybill_number: 151515,
        cod: 0,
        collectedCOD: 0,
        city: 'Galle',
        totalCom: 11.30,
        payable: 1000,
        weight: 100,
        status: 'Delivered',
      },
      {
        Waybill_number: 454545,
        cod: 0,
        collectedCOD: 0,
        city: 'Colombo',
        totalCom: 11.30,
        payable: 1000,
        weight: 100,
        status: 'Delivered',
      },
      {
        Waybill_number: 544545,
        cod: 0,
        collectedCOD: 0,
        city: 'Matara',
        totalCom: 11.30,
        payable: 1000,
        weight: 100,
        status: 'Delivered',
      },
    ]
    const invoiceData = {
      id: 0,
      issuedDate: '',
      dueDate: '',
      client:
          {
            name: 'Justin',
            company: 'TransEx',
            address: '12/A/2, Boralesgamuwa Road, Kaduwela',
            country: 'Sri Lanka',
            contact: '0557',
            companyEmail: '@mail.com',
          },
    }
    const paymentDetails = {
      invoiceNo: 'TRA/21/42343',
      invoiceDate: '08/08/2021',
    }
    return {
      tableFieldData: {},
      avatarText,
      loading: false,
      id: null,
      invoiceTableStructure,
      paymentDetails,
      invoiceData,
      invoiceDescription,
      rows: [],
      orderRows: [],
      total_cod: 0,
      total_delivery: 0,
      total_com: 0,
      bucketUrl,
      defaultCompanyLogo,
      businessSettings: null,
      companyCurrency: null,
      taxDetails: {},
      taxes: [],
      taxNames: '',
      fields: [
        {
          key: 'waybill_number',
          label: 'Waybill Number',
          sortable: true,
          tdClass: 'text-left',
        },
        {
          key: 'customer_name',
          label: 'Customer Name',
          sortable: true,
        },
        {
          key: 'customer_email',
          label: 'Customer Contact',
          sortable: false,
          tdClass: 'text-left',
          thClass: 'text-left',
        },
        {
          key: 'finance',
          label: 'Finance',
          sortable: false,
        },
        {
          key: 'description',
          label: 'Description',
          sortable: false,
          tdClass: 'text-left',
          thClass: 'text-left',
        },
        {
          key: 'status.key',
          label: 'Status',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      walkingCustomer,
      walkingInvoiceTableStructure: [
        {
          key: 'card_name',
          label: 'Name',
        },
        {
          key: 'order_count',
          label: 'Order Count',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'delivery_charge',
          label: 'Delivery Charge',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      walkingInvoiceData: [
      ],
      cssText: `
      body {
        background-color: transparent !important;
      }
      * {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
      }
      b {font-weight:500; }
      .invoice-preview-card {
        font-family:Montserrat,Helvetica,Arial,serif;
        font-size:12px;
        font-weight:600; 
      } 
      .invoice-title {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 1rem;
      } 
      .invoice-order-table th,
      .invoice-order-table td {
        border-top: 1px solid #ebe9f1;
        padding: 12px 4px;
        text-align: left;
      }
      .invoice-order-table td.text-center {
        text-align: center;
      }
      .invoice-order-table th {
        text-transform: uppercase;
      }
      .delivery-management-service-table th {
        text-align: left;
        border-top: 1px solid #ebe9f1;
        border-bottom: 1px solid #ebe9f1;
        padding: 12px 8px;
        text-transform: uppercase;
      }  
      .delivery-management-service-table td {
        padding: 12px 8px;
        text-align: right;
      }
      .delivery-management-service-table th:first-child,
      .delivery-management-service-table td:first-child {
        text-align: left; 
        width: 100px;
      }
      .table {
        th:first-child,
        td:first-child {
          padding-left: 2.5rem;
        }
      }
      .account-statement {
        margin-top: 8px;
        margin-bottom: 8px;
        width: 150px;
      }
      .account-statment-table td {
        width: 150px;
      }
      .print-layout {
        display: flex;
        align-items: center;
      }
      .print-layout .avatar-text {
        margin-right: 8px;
        background-color: #dceffc;
        color: #2aabfc;
        width: 50px;
        height: 50px; 
        border-radius: 50%;
        display: flex;      
        justify-content: center;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
        padding: 0; 
      }
      .waybill-number-cell{
        color: #2aabfc;
      }
      .waybill-number-text{
        color: black;
      }
      .invoice-margin {
        margin-bottom: 1rem;
      }
      .business-details{
        margin-top: 20px;
        margin-bottom: 20px;
      }
      hr {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
      }
      .merchant-detatils {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
        display: flex !important;
        flex-wrap: wrap !important;
      }
      .invoice-number {
        display: flex !important;
        margin-left: auto !important;
      }
      .invoice-padding {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }
      .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
      }
      .card {
        background-color: transparent;
        box-shadow: none;
      }
      .invoice-date-title {
        width: 7rem;
        margin-bottom: 0;
      }
      .invoice-date {
        margin-left: 0.5rem;
        font-weight: 600;
        margin-bottom: 0;
      }
      .invoice-spacing {
        margin: $invoice-spacing;
      }
      .invoice-number-date {
        .title {
          width: 115px;
        }
      }
      .invoice-total-wrapper {
        width: 100%;
        max-width: 12rem;
        .invoice-total-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .invoice-total-title {
            margin-bottom: 0.35rem;
          }
          .invoice-total-amount {
            margin-bottom: 0.35rem;
            font-weight: 600;
          }
        }
      }
      .invoice-preview-wrapper {
        .row.invoice-preview {
          .col-md-8 {
            max-width: 100%;
            flex-grow: 1;
          }

          .invoice-preview-card {
            .card-body:nth-of-type(2) {
              .row {
                > .col-12 {
                  max-width: 50% !important;
                }

                .col-12:nth-child(2) {
                  display: flex;
                  align-items: flex-start;
                  justify-content: flex-end;
                  margin-top: 0 !important;
                }
              }
            }
          }
        }
      }
      .status-badge {
        display: inline-block;
        white-space: normal;
        padding: 0.5em;
        border-radius: 0.25em;
      }
      .text-container {
        max-width: 100%;
        word-wrap: break-word;
        white-space: normal;
      }
      .badge-light-warning {
        background-color: #f9ede2;
        color: #ffa561;
      }
      .badge-light-success {
        background-color: #e1f4ea;
        color: #52d18a;
      }
      .badge-light-danger {
        background-color: #f8e6e7;
        color: #ec6a6b;
      }
      .badge-light-primary {
        background-color: #daedf9;
        color: #54aefb;
      }
      .cod-section {
        display: flex;
        justify-content: space-between;
      }
      .finance-section {
        text-align: right;
        width: 50px;
      }`,
    }
  },
  computed: {
    tableField: {
      // async get() {
      //   return {
      //     advanced: 0,
      //     balancePaid: 0,
      //     due: 0,
      //   }
      // },
      set(dataArray) {
        const [advanced, balancePaid, due] = dataArray
        this.tableFieldData.advanced = advanced
        this.tableFieldData.balancePaid = balancePaid
        this.tableFieldData.due = due
      },
    },
  },
  async created() {
    this.id = this.$route.params.id
    this.businessSettings = JSON.parse(localStorage.getItem('businessSettings'))
    this.companyCurrency = this.businessSettings.company_currency
  },

  async mounted() {
    await this.fetchDataForMonthlySales()
    await this.fetchWaybillForMerchant()
    this.businessSettings = JSON.parse(localStorage.getItem('businessSettings'))
    this.companyCurrency = this.businessSettings.company_currency
  },
  methods: {
    async fetchDataForMonthlySales() {
      try {
        this.loading = true
        const { data } = (await FinanceRepository.getInvoiceData(this.id)).data
        if (data.tax_breakdown !== null) {
          this.walkingInvoiceData.push(
            { card_name: '', delivery_charge: data.invoice_amount, order_count: `Before Tax Amount (${this.companyCurrency})` },
            { card_name: '', delivery_charge: 'NA', order_count: 'Tax Breakdown' },
          )
          this.taxes = data.tax_breakdown
          this.taxes.forEach(tax => {
            if (tax.tax_rate_type === 'Fixed') {
              this.walkingInvoiceData.push(
                { card_name: '', delivery_charge: tax.tax_amount, order_count: `${tax.tax_name}(${tax.rate_applied})` },
              )
            } else {
              this.walkingInvoiceData.push(
                { card_name: '', delivery_charge: tax.tax_amount, order_count: `${tax.tax_name}(${tax.rate_applied}%)` },
              )
            }
          })
          this.walkingInvoiceData.push(
            { card_name: '', delivery_charge: data.total_tax_applied, order_count: `Total Tax Applied (${this.companyCurrency})` },
            { card_name: '', delivery_charge: data.invoice_amount_after_taxes, order_count: `After Tax Amount (${this.companyCurrency})` },
          )
        }
        this.walkingInvoiceData.push(
          { card_name: '', delivery_charge: data.total_collected_cod, order_count: `Advance Paid (${this.companyCurrency})` },
          { card_name: '', delivery_charge: data.total_balance_paid, order_count: `Balance Paid (${this.companyCurrency})` },
          { card_name: '', delivery_charge: (data.rate_card.rate_card[0].delivery_charge + parseFloat(data.total_tax_applied)) - (data.total_collected_cod + data.total_balance_paid), order_count: `Due (${this.companyCurrency})` },
        )
        this.rows = data.rate_card.rate_card
        this.allItems = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchWaybillForMerchant() {
      try {
        this.loading = true
        const { data } = (await FinanceRepository.getAllMechantOrdersListNoPagination(this.id))
        this.orderRows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    onClickViewOrder(id) {
      this.$refs.viewOrderModal.openModal(id)
    },
    printInvoice() {
      const printd = new Printd()
      printd.print(document.getElementById('invoice-preview-card'), [this.cssText])
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
.taxSection {
    display: contents;
}
@media print {
  @page {
    size: A4
  }
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .taxSection {
    display: contents;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
